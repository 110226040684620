<template>
    <div>
        <nav class="navbar navbar-expand-sm fixed-bottom main-menu">
            <ul class="navbar-nav w-100 justify-content-center main-menu-wrapper flex-nowrap">
                <li class="nav-item flex-fill">
                    <NuxtLink to="/" :prefetch="true">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="tasks" />
                            <div class="main-menu-item-text">{{ $t("actions.header") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li v-if="store.activeRide" class="nav-item flex-fill">
                    <NuxtLink :to="{ name: 'driver-cmr' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="file" />
                            <div class="main-menu-item-text">
                                {{
                                    // authStatus === "authenticated" && authData.value && authData.value?.driver && authData.value?.driver.metrans
                                    authStatus === "authenticated" &&
                                    authData?.id &&
                                    authData?.driver &&
                                    authData?.driver.ciziTahac
                                        ? $t("cmr.header")
                                        : $t("cmr.containers")
                                }}
                            </div>
                        </div>
                    </NuxtLink>
                </li>

                <li
                    v-if="
                        authStatus === 'authenticated' && authData?.id && authData?.driver && authData?.driver.ciziTahac
                    "
                    class="nav-item flex-fill"
                >
                    <NuxtLink :to="{ name: 'driver-arrivals' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="history" />
                            <div class="main-menu-item-text">{{ $t("label.history") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li
                    v-if="
                        authStatus === 'authenticated' &&
                        authData?.id &&
                        authData?.driver &&
                        !authData?.driver.ciziTahac
                    "
                    class="nav-item flex-fill"
                >
                    <NuxtLink :to="{ name: 'driver-history' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="history" />
                            <div class="main-menu-item-text">{{ $t("otherRides.header") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li class="nav-item flex-fill">
                    <NuxtLink :to="{ name: 'driver-chat' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="comment-dots" />
                            <div class="main-menu-item-text">
                                {{ $t("chat.header") }}
                                <span v-if="store.newMessagesCount > 0" class="badge bg-success">{{
                                    store.newMessagesCount
                                }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
const route = useRoute();
const toast = useToast();

const connectionStore = useConnectionStore();
const { t } = useI18n();

const { status: authStatus, data: authData } = useAuth();

const store = useStore();
onMounted(() => {
    chatMessageListener();
});

function chatMessageListener() {
    connectionStore.socket.on("chat.message", (message) => {
        toast.success(`${t("label.newMessage").toString()}: ${message}`);
        if (route.name !== "driver-chat") {
            store.setNewMessagesCount(store.newMessagesCount + 1);
        }
    });
}

watch(
    () => connectionStore.socket.connected,
    async (newSocket) => {
        if (newSocket) {
            chatMessageListener();
        }
    },
    { deep: true }
);
</script>
